import {LitElement, html} from 'lit';
import {classMap} from 'lit/directives/class-map.js';


export class EventItem extends LitElement {
  static get properties() {
    return {
      event: {type: Object},
      href: {type: String},
      small: {type: Boolean}
    }
  }

  getEvent() {
    if (this.event) {
      return this.event;
    }
    return this._event;
  }

  _event = {};

  firstUpdated() {
    this._event = this.event;
    this.removeAttribute('event');
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div class="event ${this.small ? 'event--small' : ''}">
        <div class="event__content ${classMap({'event__content--with-image': this.getEvent().cover})}">
          ${this.getEventHeader()}
          <div class="row">
            ${this.getTags()}
            ${this.getImageOpenButton()}
          </div>
        </div>
        <div class="event__image">
          ${this.getCover()}
        </div>
      </div>
    `
  }

  getEventHeader() {
    var content = html`
      <div class="event__header">
        <event-date
          startDate="${this.getEvent().start_date}"
          endDate="${this.getEvent().end_date}"
        ></event-date>
        <div class="event__title ${classMap({'event__title--with-image': this.getEvent().cover})}">
          ${this.getEmoji()}
          ${this.getTitle()}
        </div>
      </div>
    `
    if (this.href) {
      return html`
        <a href="${this.href}" style="flex-grow: 1;">
          ${content}
        </a>
      `
    }
    return content;
  }

  getTitle() {
    return html`${this.getEvent().title}`
  }

  getCover() {
    if (this.getEvent().cover) {
      return html`
        <cover-image
          src="${this.getEvent().cover.url}"
          thumbnailSrc="${this.getEvent().cover_thumbnail.url}"
          alt="${this.getEvent().full_title}"
          style="border-radius: var(--border-radius)"
        >
        </cover-image>
      `
    } else {
      return html`
        <cover-image
          alt="${this.getEvent().full_title}"
          style="border-radius: var(--border-radius)"
        >
        </cover-image>
      `
    }
  }

  getEmoji() {
    if (this.getEvent().emoji) {
      return html`
        <span style="margin-right: 0.1em">
          ${this.getEvent().emoji}
        </span>
      `
    }
  }

  getTags() {
    if (this.getEvent().tags && this.getEvent().tags.length > 0) {
      return html`
        <div class="tag__wrapper tag__wrapper--card">
          ${this.getEvent().tags.map(tag => html`
            <span class="tag tag--with-image">
              ${tag.title}
            </span>
          `)}
        </div>
      `
    }
  }

  getImageOpenButton() {
    if (this.getEvent().cover) {
      return html`
          <button
            onclick="(event) => {event.stopPropagation()}"
            class="button button--icon"
            style="z-index: 10; padding: 24px; margin: -24px; margin-left: auto;"
            @click="${() => {
              this.querySelector('cover-image').querySelector('a').click();
            }}"
            title="Open image"
          >
            <svg viewBox="0 0 24 24">
              <use href="${window.spriteFilePath}#image"></use>
            </svg>
          </button>
      `
    }

  }
}

customElements.define('event-item', EventItem);
