import {LitElement, html} from 'lit';
import {createRef, ref} from 'lit/directives/ref.js';
import {msg} from '@lit/localize';

class PWAInstallBUtton extends LitElement {
  static get properties() {
    return {
      value: {type: Object},
    }
  }

  deferredPrompt = null

  installButtonRef = createRef()

  firstUpdated() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.deferredPrompt = e;
      this.installButtonRef.value.style.display = 'flex';
    });
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div ${ref(this.installButtonRef)} class="button button--rounded" style="display: none; padding: 12px;" @click="${this.install}">
        <div class="download-arrow"></div>
        ${msg("INSTALL THE APP")}
      </div>
    `;
  }

  async install() {
    if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const {outcome} = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          this.deferredPrompt = null;
        }
      }
  }
}

customElements.define('pwa-install-button', PWAInstallBUtton);
