import {LitElement, html} from 'lit';
import SimpleLightbox from "simplelightbox";
import {createRef, ref} from 'lit/directives/ref.js';

export class CoverImage extends LitElement {
  static get properties() {
    return {
      src: {type: String},
      thumbnailSrc: {type: String},
      alt: {type: String}
    }
  }

  coverRef = createRef();
  lightbox = null;

  firstUpdated() {
    if (this.src) {
      this.lightbox = new SimpleLightbox(
        [this.coverRef.value],
        {
          fileExt: false,
          scrollZoomFactor: 0.05,
          overlayOpacity: 0.9,
          widthRatio: 1,
        }
      )
    }
  }

  createRenderRoot() {
    return this;
  }

  render() {
    if (this.src) {
      return html`
        <link rel="preload" as="image" href="${this.src}">
        <a href="${this.src}" ${ref(this.coverRef)} alt="${this.alt}">
          <img src="${this.thumbnailSrc}"
               class="cover"
               alt="${this.alt}"
          >
        </a>
      `
    }
    return html`
      <div class="event event--cover">
        <div class="event__placeholder" style="background-image: url('${window.placeholderImage}')"></div>
      </div>
    `;
  }
}

customElements.define('cover-image', CoverImage);
