import {LitElement, html} from 'lit';
import {msg} from '@lit/localize';

export class EventDate extends LitElement {
  static get properties() {
    return {
      startDate: {type: String, reflect: true},
      endDate: {type: String, reflect: true},
    }
  }


  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div class="event__date">
        <div class="event__day">
          ${new Date(this.startDate).toLocaleDateString(window.userLang, {day: 'numeric'})}
        </div>
        <div class="event__month">
          ${new Date(this.startDate).toLocaleDateString(window.userLang, {month: 'long'})}
        </div>
        <div class="event__divider">
        </div>
        <div class="event__year">
          ${new Date(this.startDate).toLocaleDateString(window.userLang, {year: 'numeric'})}
        </div>
        ${this.getEndDate()}
      </div>
    `
  }

  getEndDate() {
    if (this.endDate) {
      return html`
        <div class="event__end-date">
          ${msg('until')}<br>
          ${new Date(this.endDate).toLocaleDateString(window.userLang, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          })}
        </div>
      `
    }
  }
}

customElements.define('event-date', EventDate);
